<template>
  <div class="">

    <form @submit.prevent="submitTerrain">
      
      <div class="row">

          

          <div class="col-xl-3">
            <label for="price">Hours</label>
            <input type="text" v-model="terrain.fullName" class="form-control">
          </div>

         
      </div>
      <button type="submit" class="btn btn-primary mt-3">
        Create terrain
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      terrain: {
        name: '',
        email: '',
        startTime: '',
        endTime: ''
      },
      
    };
  },
  async mounted() {
  },
  methods: {
    
  
    submitTerrain() {
      this.$store.dispatch("terrain/store", this.terrain);
      console.log('terrain details:', this.terrain);
    },
    
  },
  computed: {
    
  },
};
</script>

<style scoped>

</style>
